import React from 'react'
import styled from 'styled-components'
import { theme, fontBold } from '../util/style'

export default ({ children, sepColor, fontColor }) => {
  return (
    <List sepColor={sepColor} fontColor={fontColor}>
      {children}
    </List>
  )
}

const List = styled.ul`
  margin: 0 0 1em 0;
  padding: 0;
  color: ${({ fontColor }) => fontColor};
  strong {
    ${fontBold()};
  }
  font-size: 95%;
  position: relative;
  top: -0.6em;
  li {
    border-bottom: 1px solid ${({ sepColor }) => sepColor};
    list-style-type: none;
    margin: 0;
    padding: 0.5em 0 0.5em 0;
  }
  @media (max-width: 800px) {
    margin-bottom: -50px;
    li {
      padding: 0.5em 0 0.5em 0;
    }
  }
`
