import React, { Component } from 'react'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import { connect } from 'react-redux'
import Title from '../components/page-title'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import Image from '../components/page-image-new'
import PageButtons from '../components/page-buttons'
import { theme } from '../util/style'
import PageCtaFeatureList from '../components/page-cta-feature-list'
import { setLightboxForm } from '../actions'
import PageCtaFeature from '../components/page-cta-feature'
import PageVideo from '../components/page-video'
import PageOverview from '../components/page-overview'
import PageRuledList from '../components/page-ruled-list'
import PageHeroGallery from '../components/page-hero-gallery'

const DemoVideo = 'https://youtu.be/twzTW78Zza0'
const mapDispatchToProps = (dispatch) => ({})

const LandscapeImage1 = 'resources/happy-haus-wollumbin-11.jpg'
const LandscapeImage2 = 'resources/happy-haus-helensvale-10.jpg'
const Image1 =
  'resources/happyhaus-gallery-2021-03-12-photo-derrer-JG-1160601-2.jpg'
import Image2 from '../images/resources/knockdown.jpg'
const Guide1Image = 'resources/happy-haus-holland-park-west-13.jpg'
const Guide2Image = 'resources/happyhaus-guide-land-cover.png'

class Page extends Component {
  constructor(props) {
    super(props)
    this._handleRequestClick = this._handleRequestClick.bind(this)
  }

  _handleRequestClick(e, form) {
    e.preventDefault()
    const { dispatch } = this.props

    switch (form) {
      case 'knockdown':
        dispatch(
          setLightboxForm({
            category: 'Land Guide',
            title: 'Get your Land Guide',
            redirect: '/guide-knockdown-rebuild-thank-you',
          })
        )
        break

      case 'new':
        dispatch(
          setLightboxForm({
            category: 'Build Guide New',
            title: 'Get your free guide<br />to building your new home',
            redirect: '/build-guides-thank-you',
          })
        )
        break

      default:
        break
    }
  }

  render() {
    const CtaFeatures = [
      {
        title: '&nbsp;',
        image: Guide2Image,
        buttonLabel: 'Download our guide',
        fgColor: '#303030',
        bgColor: '#81868B',
        style: 'cols',
        color: '#FFFFFF',
        plus: 'Plus! Compare land on-the-go with our handy checklist',
        content: (
          <PageCtaFeatureList sepColor="#FFFFFF" fontColor="#FFFFFF">
            <li>
              <strong>Our guide has expert tips to help you</strong>
            </li>
            <li>
              Consider key factors for the location and the site conditions
            </li>
            <li>Learn what can be built based on frontage, size and shape</li>
            <li>Assess opportunities to maximise site slope and orientation</li>
            <li>Understand how zoning and overlays can affect development</li>
            <li>Take into account flooding and bushfire build requirements.</li>
            <br />
            <br />
            <br />
            <br />
          </PageCtaFeatureList>
        ),
        onClick: (e) => {
          this._handleRequestClick(e, 'knockdown')
        },
      },
    ]

    return (
      <>
        <SEO
          title="Land Guide"
          description="Securing a block of land should be one of many high points in your new home journey. This guide is designed to help you identify some of the pitfalls, opportunities and resources available when looking to buy land, more specifically, to ensure it is suitable for building the home that you desire within budget."
          images={[LandscapeImage1, LandscapeImage2, Guide1Image]}
        />

        <Row>
          <Flex flexWrap="wrap" flexDirection={['column-reverse', 'row']}>
            <Box width={[1, 6 / 12]} px={2}>
              <Title>Looking for the right land to build on?</Title>
            </Box>
            <Box width={[1, 6 / 12]} px={2}>
              <Copy align="left">
                <p>
                  Choosing the right piece of earth on which to build your dream
                  home can be a great experience, especially if your equipped
                  with a solid understanding of what you should be looking for.
                </p>
              </Copy>
            </Box>
          </Flex>
        </Row>

        <PageCtaFeature features={CtaFeatures} />

        <Row>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Box width={[1, 6 / 12]} px={2} pr={[0, 0]}>
              <Title>Feel more informed when buying to build</Title>
            </Box>
            <Box width={[1, 6 / 12]} px={2} mr={[0, 0]}>
              <Copy align="left">
                <p>
                  Securing a block of land should be one of many high points in
                  your new home journey. This guide is designed to help you
                  identify some of the pitfalls, opportunities and resources
                  available when looking to buy land, more specifically, to
                  ensure it is suitable for building the home that you desire
                  within budget.
                </p>
                <p>
                  Choosing the right piece of earth on which to build your dream
                  home can be a great experience, especially if your equipped
                  with a solid understanding of what you should be looking for.
                </p>
                <p>
                  <img src={Image2} ratio={1 / 1.5} />
                </p>
                <p>
                  Discovering issues after you’ve acquired land can be an
                  expensive experience and may significantly restrict the type
                  of home you’ll be able to build.
                </p>
                <p>
                  <a
                    onClick={(e) => {
                      e.preventDefault()
                      this._handleRequestClick(e, 'knockdown')
                    }}
                  >
                    <strong>Download our guide and checklist</strong>
                  </a>
                </p>
              </Copy>
            </Box>
          </Flex>
        </Row>

        <Row>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Box width={[1]} px={2} pr={[0, 0]}>
              <Image src={Image1} alt="Guide Land" />
            </Box>
          </Flex>
        </Row>

        {/* <PageVideo src={DemoVideo} /> */}
      </>
    )
  }
}

export default connect(mapDispatchToProps)(Page)
